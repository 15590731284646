<template>
    <PageElementWrapper>
        <div class="grid place-items-center px-6 py-24 sm:py-32 lg:px-8">
            <div class="text-center">
                <p class="text-base font-semibold text-primary">
                    404
                </p>
                <h1 class="mt-4 text-balance text-5xl font-semibold tracking-tight text-gray-900 sm:text-7xl">
                    Seite nicht gefunden
                </h1>
                <p class="mt-6 text-pretty text-lg font-medium text-gray-500 sm:text-xl/8">
                    Die aufgerufene Seite existiert leider nicht
                </p>
                <div class="mt-10 flex items-center justify-center gap-x-6">
                    <BaseButton
                        color="gray"
                        size="sm"
                        @click="useRouter().push(localePath('/'))">
                        Zurück zur Startseite
                    </BaseButton>
                </div>
            </div>
        </div>
    </PageElementWrapper>
</template>